<template>
  <div class="card "> <!--Document -->
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">Documents</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a
              v-b-toggle="`collapseFileCard`"
              data-action="collapse"
            ><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseFileCard`"
      visible
    >
      <div class="card-body">
        <b-tabs>
          <b-tab
            :active="activeTab == 0"
            @click="activeTab = 0"
          >
            <template #title>
              Upload docs
            </template>
            <p>{{ labels['upload_docs_description'] }}</p>
            <div
              v-if="status != 0"
              :class="`alert alert-${statusType} w-25 p-1`"
            >
              <div class="row">
                <div class="col-1">
                  <div
                    v-if="status == 1"
                    class="loading custom-loading"
                  >
                    <div class="effect-1 effects" />
                    <div class="effect-2 effects" />
                    <div class="effect-3 effects" />
                  </div>
                  <i
                    v-if="status == 2"
                    data-feather="check"
                  />
                  <i
                    v-if="status == 3"
                    data-feather="x"
                  />
                </div>
                <div class="col-11 center-vertically">
                  <span v-if="status == 1">
                    Checking if the files are correct...
                  </span>
                  <span v-if="status == 2">
                    File matches correctly found!
                  </span>
                  <span v-if="status == 3">
                    These files didn't match with any user: <strong v-html="errorFiles" />
                  </span>
                </div>
              </div>
            </div>
            <div class="card-header d-flex align-items-center justify-content-between">
              <div>
                <h4><strong>{{ data.length }}</strong> documents</h4>
              </div>
            </div>
            <div class="table-responsive-sm overflow-scroll">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Title</th>
                    <!-- <th>Follow</th> -->
                    <th>Need feedback</th>
                    <th>Deadline</th>
                    <th>Year</th>
                    <th>Renew</th>
                    <th>Archived date</th>
                    <th class="text-end">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="file, index in data"
                    :key="file.id"
                  >
                    <td>{{ file.title ? file.title : file.real_title }}</td>
                    <!-- <td>
                      <div class="form-check form-check-success">
                        <input
                          id="colorCheck3"
                          v-model="file.follow"
                          type="checkbox"
                          class="form-check-input"
                          :checked="file.follow"
                        >
                      </div>
                    </td> -->
                    <td>
                      <div class="form-check form-check-success">
                        <input
                          id="colorCheck3"
                          v-model="file.need_feedback"
                          type="checkbox"
                          class="form-check-input"
                          :checked="file.need_feedback"
                        >
                      </div>
                    </td>
                    <td>
                      <date-picker
                        v-model="file.deadline"
                        format="YYYY-MM-DD HH:mm:ss"
                        value-type="format"
                      />
                    </td>
                    <td>
                      <date-picker
                        v-model="file.year"
                        format="Y"
                        type="year"
                        value-type="format"
                        class="w-100"
                      />
                    </td>
                    <td>
                      <date-picker
                        v-model="file.renew_year"
                        format="Y"
                        type="year"
                        value-type="format"
                        class="w-100"
                      />
                    </td>
                    <td>
                      <date-picker
                        v-model="file.archived_date"
                        format="YYYY-MM-DD HH:mm:ss"
                        value-type="format"
                      />
                    </td>
                    <td class="text-end">
                      <a
                        :href="file.url"
                        target="_blank"
                        class="btn btn-icon btn-icon rounded-circle btn-flat-dark"
                      >
                        <i data-feather="eye" />
                      </a>
                      <a
                        v-b-toggle="`collapseFile`"
                        class="btn btn-icon btn-icon rounded-circle btn-flat-dark"
                        @click="editFile(file, index)"
                      >
                        <i data-feather="edit" />
                      </a>
                      <a
                        class="btn btn-icon btn-icon rounded-circle btn-flat-danger"
                        @click="deleteFile(file.id, index)"
                      >
                        <i data-feather="trash-2" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr>
              <div
                v-if="loggedUser.roles.includes('super-admin')"
                class="mb-1"
              >
                <div class="form-check form-check-success mb-2">
                  <input
                    id="sendCAE"
                    :checked="cae"
                    type="checkbox"
                    class="form-check-input"
                    @click="$emit('changeToCae', $event.target.checked)"
                  >
                  <label
                    class="form-check-label"
                    for="sendCAE"
                  >{{ labels['send_to_cae'] }}</label>
                </div>
              </div>
            </div>

            <div :ref="`new-file-table`">
              <a
                v-b-toggle="`collapseFile`"
                class="btn btn-sm btn-dark dis-ib"
              >
                <i data-feather="plus" /> Add file
              </a>
              <a
                class="btn btn-sm btn-dark dis-ib ms-1"
                @click="changeTitles"
              >
                <i data-feather="edit-2" /> Change all file titles
              </a>
              <input
                v-model="newTitle"
                class="form-control w-25 ms-1"
                type="text"
                style="display: inline-block;"
              >

              <b-collapse
                :id="`collapseFile`"
                class="collapse mt-3"
              >
                <div class="card shadow-none bg-transparent border-secondary">
                  <div class="card-body">
                    <h3>Add file</h3>
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <div
                            class="input-group custom-file-button"
                          >
                            <label
                              class="input-group-text"
                              for="inputGroupFile"
                            >Select file</label>
                            <input
                              id="formFile"
                              :ref="`file-new`"
                              class="form-control"
                              type="file"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['name'] }}</label>
                          <input
                            v-model="title"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div>
                    </div>
                    <div class="mb-2">
                      <a
                        v-b-toggle="`collapseFile`"
                        class="btn btn-sm btn-outline-success me-1"
                        @click="uploadFile"
                      >{{ editingFile.file || editingFile.id ? 'Edit' : 'Upload' }} file</a>
                      <a
                        v-b-toggle="`collapseFile`"
                        class="btn btn-sm btn-flat-danger"
                        @click="cancelEdit"
                      >Cancel</a>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </b-tab>

          <b-tab
            :active="activeTab == 1"
            @click="activeTab = 1"
          >
            <template #title>
              Upload massive docs
            </template>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia, ipsa consectetur sint nostrum placeat corrupti explicabo deleniti dolores voluptate distinctio dolor alias dolore, eius dignissimos pariatur in, debitis non iusto.</p>
            <vue-dropzone
              id="dropzone"
              ref="myVueDropzone"
              class="dropzone-files"
              :options="dropzoneOptions"
              @vdropzone-files-added="sendingFile"
            />
            <hr>
            <!-- <div
              v-if="loggedUser.roles.includes('super-admin')"
              class="mb-1"
            >
              <div class="form-check form-check-success mb-2">
                <input
                  id="sendCAE"
                  :checked="cae"
                  type="checkbox"
                  class="form-check-input"
                  @click="$emit('changeToCae', $event.target.checked)"
                >
                <label
                  class="form-check-label"
                  for="sendCAE"
                >Send to CAE</label>
              </div>
            </div> -->
          </b-tab>
        </b-tabs>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import Vue from 'vue'
import { BCollapse, BTab, BTabs } from 'bootstrap-vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vue2Dropzone from 'vue2-dropzone'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCollapse,
    BTab,
    BTabs,
    vueDropzone: vue2Dropzone,
    DatePicker,
  },
  props: {
    data: { type: Array, required: false, default: () => [] },
    cae: { type: Boolean, required: false, default: false },
    hazard: { type: Object, required: true },
  },
  data() {
    return {
      newTitle: null,
      title: null,
      activeTab: 0,
      status: 0,
      editingFile: {},
      editingIndex: null,
      statusType: 'info',
      errorFiles: '',
      dropzoneOptions: {
        thumbnailWidth: 150,
        acceptedFiles: 'application/pdf',
        maxFilesize: 10,
        url: '#',
        uploadMultiple: true,
        headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` },
      },
      labels: [],
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.documents`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 1000)
  },
  methods: {
    uploadFile() {
      const file = this.$refs['file-new'].files[0]
      if (this.editingFile.file || this.editingFile.id) {
        Vue.set(this.data, this.editingIndex, {
          id: this.editingFile.id,
          need_feedback: this.editingFile.need_feedback,
          deadline: this.editingFile.deadline,
          year: this.editingFile.year,
          renew_year: this.editingFile.renew_year,
          archived_date: this.editingFile.archived_date,
          title: this.title ?? file.name,
          file: file || this.editingFile,
          follow: true,
          url: file ? URL.createObjectURL(file) : this.editingFile.url,
        })

        this.$emit('saveFiles', this.data)
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)

        this.title = null
        this.editingFile = {}
        this.$refs['file-new'].value = null
      } else if (file) {
        this.data.push({
          title: this.title ?? file.name,
          file,
          follow: true,
          url: URL.createObjectURL(file),
        })

        this.$emit('saveFiles', this.data)
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)

        this.title = null
        this.editingFile = {}
        this.$refs['file-new'].value = null
      } else {
        Vue.swal('Please, upload a file first!', '', 'warning')
      }

      this.cancelEdit()
    },
    editFile(file, index) {
      this.$refs['new-file-table'].scrollIntoView()
      this.title = file.title
      this.editingFile = file
      this.editingIndex = index
    },
    cancelEdit() {
      this.title = null
      this.editingFile = {}
      this.editingIndex = null
    },
    sendingFile(files) {
      let name = ''

      if (this.hazard.subsections && this.hazard.subsections[0]) {
        name = this.hazard.subsections[0].name
      } else if (this.hazard.section) {
        name = this.hazard.section.name
      }

      this.data.push({
        files: Array.from(files).map(i => ({
          title: name !== '' ? name : i.name,
          match: i.name,
          file: i,
          url: URL.createObjectURL(i),
        })),
      })

      this.$emit('saveFiles', this.data)
      this.$emit('multipleFilesTrigger', true)
      this.$toastr.success('', 'Files imported successfully, edit in "Upload Docs" tab')

      this.checkForIdentifyMatches()

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
        this.activeTab = 0
      }, 500)
    },
    deleteFile(fileId, index) {
      if (fileId) {
        this.$store.dispatch('labourHazards/deleteFile', fileId)
      }

      this.data.splice(index, 1)
      Vue.swal('File deleted successfully!', '', 'success')
    },
    async checkForIdentifyMatches() {
      this.status = 1

      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards/files/check-multiple-files`, { files_h: this.data }).catch(() => {
        this.status = 3
        this.statusType = 'danger'
      })

      const filesKo = []

      if (resp.status === 200) {
        resp.data.forEach(item => {
          if (item.files) {
            item.files.forEach(file => {
              if (!file.matched) {
                filesKo.push(file.match)
              }
            })
          }
        })

        if (filesKo.length > 0) {
          this.errorFiles = filesKo.join(', <br>')
          this.status = 3
          this.statusType = 'danger'
        } else {
          this.status = 2
          this.statusType = 'success'
        }
      } else {
        this.status = 3
        this.statusType = 'danger'
      }

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
    changeTitles() {
      Vue.swal({
        title: `Do you want to change all file titles for "${this.newTitle}"?`,
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          this.data.map(e => {
            if (e.files) {
              e.files.map(f => {
                f.title = this.newTitle
                return f
              })
            }

            e.title = this.newTitle
            return e
          })
          console.log(this.data)
          this.newTitle = null
          this.$emit('saveFiles', this.data)
        }
      })
    },
  },

}
</script>
